import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from '../images/logo.png'
import Instagram from '../images/instagram.svg'
import Phone from '../images/phone.svg'
import Marker from '../images/marker.svg'

const IndexPage = () => (
  <Layout>
    <SEO title="All Asia Grocery" />
    <section className='splash'>
      <div className='splash__content'>
        <img className='splash__logo' src={Logo} alt='All Asia Grocery' />
        <h1>We showcase the diversity of what Asia has to offer.</h1>
        <ul className='splash__contact'>
          <li>
            <a href='https://www.instagram.com/allasiagrocery' rel='noopener noreferrer' target='_blank'>
              <img src={Instagram} alt='All Asia Grocery' />
              <span>@allasiagrocery</span>
            </a>
          </li>
          <li>
            <a href='tel:+61384075013'>
              <img src={Phone} alt='All Asia Grocery' />
              <span>(03) 8407 5013</span>
            </a>
          </li>
          <li>
            <a href='https://www.google.com.au/maps/place/Parkmore+Shopping+Centre/@-37.992668,145.171176,17z/data=!3m1!4b1!4m2!3m1!1s0x6ad613724f27e68d:0xf0456760532d250' rel='noopener noreferrer' target='_blank'>
              <img src={Marker} alt='All Asia Grocery' />
              <span>View Map</span>
            </a>
          </li>
          <li>
            <a href='https://www.google.com.au/maps/place/Parkmore+Shopping+Centre/@-37.992668,145.171176,17z/data=!3m1!4b1!4m2!3m1!1s0x6ad613724f27e68d:0xf0456760532d250' rel='noopener noreferrer' target='_blank'>
              <span>Parkmore Shopping Centre<br /> 317 Cheltenham Rd, Keysborough VIC 3173</span>
            </a>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
)

export default IndexPage
